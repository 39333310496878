import React from 'react';
import { FormGroup } from '@blueprintjs/core';

import { useSearchMutation } from 'api/oppsApi';
import { SearchQueryInput } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import { OppSearchSavedSearchSelectInput } from 'app/organisms/OppSearchSavedSearchSelectInput/OppSearchSavedSearchSelectInput';
import { OppSavedSearchControls } from 'app/organisms/OppSavedSearchControls/OppSavedSearchControls';

export const OppSearchQuery = () => {
  const [_search, { isLoading }] = useSearchMutation({
    fixedCacheKey: 'opp-search'
  });

  return (
    <div className="grid grid-cols-1 divide-y dark:divide-gray-600">
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <OppSearchSavedSearchSelectInput />
        <FormGroup className="m-0 pt-0">
          <div className="flex min-w-max justify-end space-x-2">
            <OppSavedSearchControls />
          </div>
        </FormGroup>
      </div>
      <SearchQueryInput name="query" className="m-0 pt-4 border-none" loading={isLoading} />
    </div>
  );
};
