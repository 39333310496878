import React, { useMemo, useState, useCallback } from 'react';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { useGetOppAttachmentsQuery } from 'api/oppsApi';
import { createColumnHelper } from '@tanstack/react-table';
import { OppAttachmentWithSummary, OppSourceBase } from 'types/__generated__/GovlyApi';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { Select } from '@blueprintjs/select';
import { FileViewer } from 'app/molecules/FileViewer/FileViewer';
import { Tag, Button, MenuItem, CompoundTag } from '@blueprintjs/core';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';
import { getFileExtensionIconProps } from 'app/lib/file';
import { Icon } from '@blueprintjs/core';

export type OppAttachmentsTableProps = {
  id: string;
} & React.HTMLAttributes<HTMLDivElement>;

const columnHelper = createColumnHelper<OppAttachmentWithSummary>();

const ALL = { label: 'All', value: undefined } as const;

type NoticeType = { label: string; value: OppSourceBase['noticeType'] };

export const OppAttachmentsTable = ({ id, ...rest }: OppAttachmentsTableProps) => {
  const query = useGetOppAttachmentsQuery({ id });
  const attachments = useMemo(() => query.data?.results ?? [], [query.data?.results]);
  const [fileToView, setFileToView] = useState<OppAttachmentWithSummary['file'] | null>(null);

  const [noticeType, setNoticeType] = useState<NoticeType>(ALL);
  const noticeTypes = [...new Set([...attachments.flatMap(a => a.noticeTypes)])].filter(nt => nt != null);
  const noticeTypeItems = [ALL, ...noticeTypes.sort().map(nt => ({ label: nt, value: nt }))];

  const handleFileClick = useCallback((file: OppAttachmentWithSummary['file']) => {
    setFileToView(file);
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('file.name', {
        header: '',
        enableColumnFilter: false,
        cell: ({ row }) => {
          const firstSeen = row.original.postedAts?.[0];
          const lastSeen = row.original.postedAts?.slice().reverse()[0];

          return (
            <div className="flex gap-2 items-start">
              <Icon {...getFileExtensionIconProps(row.original.file?.name)} className="mt-1" />

              <div>
                <LinkTag tag="button" onClick={() => handleFileClick(row.original.file)}>
                  {row.original.file?.name ?? 'N/A'}
                </LinkTag>

                <p>{row.original.summary?.summary}</p>

                <div className="flex gap-2 flex-wrap">
                  {row.original.noticeTypes?.map(nt => (
                    <Tag key={nt} minimal intent="success">
                      {nt}
                    </Tag>
                  ))}

                  {firstSeen && (
                    <CompoundTag leftContent="First Seen" minimal intent="primary">
                      {formatTime(firstSeen, DATETIME_24_SHORT)}
                    </CompoundTag>
                  )}

                  {lastSeen && (
                    <CompoundTag leftContent="Last Seen" minimal intent="primary">
                      {formatTime(lastSeen, DATETIME_24_SHORT)}
                    </CompoundTag>
                  )}

                  {row.original.warningTags?.map(wt => (
                    <Tag key={wt} minimal intent="warning">
                      {wt}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          );
        }
      })
    ],
    [handleFileClick]
  );

  const filteredAttachments = useMemo(() => {
    return attachments.filter(a => (noticeType.value ? a.noticeTypes?.includes(noticeType.value) : true));
  }, [attachments, noticeType]);

  return (
    <div {...rest}>
      <GovlyTable
        id="opp_attachments_table"
        columns={columns}
        data={filteredAttachments}
        isFixedLayout
        title={<GovlyTableTitle title="Attachments" />}
        rightElement={
          <GovlyTableToolbar>
            <Select
              items={noticeTypeItems}
              activeItem={noticeType}
              onItemSelect={setNoticeType}
              filterable={false}
              itemRenderer={(item, { modifiers, handleClick, handleFocus }) => (
                <MenuItem
                  text={item.label}
                  key={item.value ?? 'missing'}
                  roleStructure="listoption"
                  selected={noticeType.value === item.value}
                  active={modifiers.active}
                  onClick={handleClick}
                  onFocus={handleFocus}
                />
              )}
            >
              <Button icon="filter" text={noticeType.label} />
            </Select>
          </GovlyTableToolbar>
        }
        isLoading={query.isLoading}
        emptyStateProps={{ icon: 'paperclip', title: 'No attachments' }}
        initialState={{ pagination: { pageSize: 10 } }}
      />
      {fileToView && (
        <FileViewer
          isOpen={true}
          downloadLink={fileToView.link}
          fileName={fileToView.name}
          fileUrl={fileToView.serviceUrl ?? fileToView.link}
          onClose={() => setFileToView(null)}
        />
      )}
    </div>
  );
};
